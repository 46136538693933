import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "deploy-webapp-on-user-actions"
    }}>{`Deploy webapp on user actions`}</h1>
    <p>{`When using a static-first webapp approach, you have to make sure critical user actions trigger a deploy of your static app. Keeps your app fast and loading correctly the first time without waiting for live data to load.`}</p>
    <p>{`And in the case of new pages, you have to deploy to make the URL work in the first place. Without a deploy users get a 404 page. We'll take care of faking pages on 404 errors in a bonus video.`}</p>
    <p>{`We're using `}<a parentName="p" {...{
        "href": "https://zeit.co"
      }}>{`Zeit`}</a>{` for our deploys because I like their CLI tools and approach to building developer tools. You can use the same approach with Netlify.`}</p>
    <p>{`The basic flow works like this:`}</p>
    <ol>
      <li parentName="ol">{`User action triggers a GraphQL mutation`}</li>
      <li parentName="ol">{`Mutation resolver on server saves to database`}</li>
      <li parentName="ol">{`Resolver calls a deployment webhook`}</li>
      <li parentName="ol">{`Zeit re-deploys your app`}</li>
    </ol>
    <h2 {...{
      "id": "prep-backend-for-live-redeploys"
    }}>{`Prep backend for live redeploys`}</h2>
    <Vimeo id="397711550" mdxType="Vimeo" />
    <h2 {...{
      "id": "connect-zeit-and-github"
    }}>{`Connect zeit and github`}</h2>
    <p>{`If you haven't put your project on github yet, now's a good time. You'll need that so Zeit knows which files to deploy.`}</p>
    <Vimeo id="397711740" mdxType="Vimeo" />
    <h2 {...{
      "id": "use-deploy-hooks-to-trigger-deployments"
    }}>{`Use deploy hooks to trigger deployments`}</h2>
    <Vimeo id="397711685" mdxType="Vimeo" />
    <h2 {...{
      "id": "ensure-deployment-runs-on-save-page"
    }}>{`Ensure deployment runs on save page`}</h2>
    <Vimeo id="397717627" mdxType="Vimeo" />
    <h2 {...{
      "id": "verify-deployments-work"
    }}>{`Verify deployments work`}</h2>
    <Vimeo id="397711804" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      